import React from "react";

function LoggedUserWidget(props) {
    const {isIndexPage = false} = props;
    let content;
    if(props.logged != true) {
        content = 
            <div>    
                <a href="login">Login</a>
            </div>
            ;
    }else {
        content =
            <div>
                <span>Hi {props.userDetails.name}</span>
                { isIndexPage != false? 
                    <a style={{marginLeft: '16px',}} href="/home">Account</a>
                    :
                    null
                }
                <a style={{marginLeft: '16px',}} href="logout">Logout</a>
            </div>
            ;
    }
    if(props.loading) {
        return <div style="visible: hidden;">{content}</div>;
    }
    return <div class="user-panel">{content}</div>;
}

export default LoggedUserWidget;