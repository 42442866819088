import React from "react";
import LoggedUserWidget from './LoggedUserWidget'

class UserDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            userDetails: null,
        }

        
        fetch("/user/details")
            .then(res => {
                if(res.status == 200)
                    return res.json();
                if(res.status == 403) {
                    // window.location.href = "/login";
                    throw Error("Unauthorized");
                }
                throw Error({message: "Server error: " + res.status, result: res});
            })
            .then(
                (result) => {
                    this.setState({
                        isLoading: false,
                        userDetails: result,
                    })
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        userDetails: null,
                    });
                }
            )
    }

    // componentDidMount() {
    // }

    render() {
        const {isIndexPage = false} = this.props;
        const {isLoading, userDetails} = this.state;
        console.log(this.state);
        
        return (
            <>
            <LoggedUserWidget loaded={!isLoading} logged={userDetails!=null} userDetails={userDetails} isIndexPage={isIndexPage}/>
            </>
        )
    }
}

export default UserDetails;