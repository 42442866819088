import React from 'react'
import './LocationsContainer.css'
import { Eggy } from '@s-r0/eggy-js'
import LoadingState from '../../../utils/LoadingState';

import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import axios from 'axios';

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

function getIconByAlertType(alertType) {
    switch(alertType) {
        case "FROST":
            return "frost";
        case "HEAT":
            return "heat";
        case "WIND":
            return "wind";
        case "PRECIPITATION":
            return "water-drop";
        case "STORM":
            return "storm";
        case "TORNADO":
            return "tornado";
        default:
            return "alert";
    }
}

function getStringFromAlertType(alertType) {
    switch(alertType) {
        case "FROST":
            return "Mróz";
        case "HEAT":
            return "Upał";
        case "WIND":
            return "Wiatr";
        case "PRECIPITATION":
            return "Opady";
        case "STORM":
            return "Burza";
        case "TORNADO":
            return "Tornado";
        default:
            return "Alert";
    }
}

function LocationItem({location}) {
    return (
        <>
        <h2>{location.name}</h2>
        { location.lightningsData != null? 
            location.lightningsData.amount > 0?
            <ul className="lightningsData">
                { Date.now() - location.lightningsData.updateTime > 10 * 60_000 &&
                    <li key="0">
                        <span className="error">Nieaktualne dane - {new Date(location.lightningsData.updateTime).toLocaleString()}. Prawdopodobny problem z usługą aktualizacji pogody. Przepraszamy za utrudnienia.</span>
                    </li>
                }
                <li key="1">
                    <span className="icon icon-distance">{location.lightningsData.distance} km</span>
                </li>
                <li key="2">
                    <span className="icon icon-direction">{location.lightningsData.directory}</span>
                </li>
                <li key="3">
                    <span className="icon icon-lightnings">{location.lightningsData.amount}</span>
                </li>
                <li key="4">
                    <span className="icon icon-radar">{location.lightningsData.radarDistance} km</span>
                </li>
                <li key="5">
                    <span className="icon icon-timer">{location.lightningsData.period} min</span>
                </li>
            </ul>
            :
            <span>Brak wyładowań</span>
        :
            <span>No lightnings data</span>
        }
        { location.alertsData != null && location.alertsData.alerts.length > 0?
        <ul className="alerts">
            { location.alertsData.alerts.map((alert, index) => {
                return (
                    <li className={'alert lvl' + Math.min(3, alert.level)} key={index} title={'Od ' + new Date(alert.fromTime).toLocaleString() + ' do ' + new Date(alert.toTime).toLocaleString()}>
                        <span className={'icon icon-' + getIconByAlertType(alert.type)}>
                            { getStringFromAlertType(alert.type) } {alert.level}
                        </span>
                    </li>
                )
            }) }
        </ul>
        : ""
        }
        </>
    )
}

function show(title, message, type, duration) {
    Eggy({
        title: title,
        message: message,
        type: type,
        duration: duration,
    });
}

function LocationFormDialog(props) {
    const [loading, setLoading] = React.useState(false);
    const locationToEdit = props.locationToEdit;
    console.log(props);

    if(locationToEdit != null) {
        const onFormSubmit = function(event) {
            if(event.preventDefault != undefined)
                event.preventDefault();
            let form = event.target;
    
            console.log("XSRF-TOKEN: " + getCookie("XSRF-TOKEN"));
            console.log(document.cookie);
            // const requestOptions = {
            //     method: 'PUT',,
            //     body: JSON.stringify({
            //         id: locationToEdit.id,
            //         name: form.name.value,
            //         longitude: form.longitude.value,
            //         latitude: form.latitude.value,
            //     })
            // };
            // fetch('/api/locations', requestOptions)
            //     .then(response => response.json())
            //     .then(() => props.handleClose());

            axios.put('/api/locations', JSON.stringify({
                    id: locationToEdit.id,
                    name: form.name.value,
                    longitude: form.longitude.value,
                    latitude: form.latitude.value,
                }), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.data)
                .then(() => props.handleClose(true));
            
        };
        return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Editing location: {locationToEdit.name}</DialogTitle>
            <div className="dialog-content">
            <form id="formAddNewLocation" method="post" action="#" onSubmit={onFormSubmit}>
                    <input type="text" id="name" name="name" placeholder="Nazwa" defaultValue={locationToEdit.name}/>
                    <input type="text" id="longitude" name="longitude" placeholder="Długość geograficzna" defaultValue={locationToEdit.longitude}/>
                    <input type="text" id="latitude" name="latitude" placeholder="Szerokość geograficzna" defaultValue={locationToEdit.latitude}/>
                </form>
            </div>
            <DialogActions>
                <Button onClick={props.handleClose}
                    color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {
                    onFormSubmit({target: document.getElementById("formAddNewLocation")});
                }}
                    color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        );
    }

    const onFormSubmit = function(event) {
        if(event.preventDefault != undefined)
            event.preventDefault();
        let form = event.target;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({
                name: form.name.value,
                longitude: form.longitude.value,
                latitude: form.latitude.value,
            })
        };
        fetch('/api/locations', requestOptions)
            .then(response => {
                if(response.status == 200) {
                    props.handleClose(true);
                    return null;
                }else {
                    return response.json();
                }
            })
            .then((error) => {
                if(error == null)
                    return;
                if(error.message == undefined)
                    show("Wystąpił nieznany błąd podczas dodawania lokalizacji", "", "error", 3000);
                else
                    show("Wystąpił błąd podczas dodawania lokalizacji", error.message, "error", 3000);
            });
        
    };
    return (
    <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Add new location</DialogTitle>
            <div className="dialog-content">
            <form id="formAddNewLocation" method="post" action="#" onSubmit={onFormSubmit}>
                <input type="text" id="name" name="name" placeholder="Nazwa"/>
                <input type="text" id="longitude" name="longitude" placeholder="Długość geograficzna"/>
                <input type="text" id="latitude" name="latitude" placeholder="Szerokość geograficzna"/>
            </form>
        </div>
        <DialogActions>
            <Button onClick={props.handleClose}
                color="primary">
                Cancel
            </Button>
            <Button onClick={() => {
                onFormSubmit({target: document.getElementById("formAddNewLocation")});
            }}
                color="primary">
                Save
            </Button>
        </DialogActions>
    </Dialog>
    );
}

class LocationList extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.userDetails);
        this.state = {
            loadingState: LoadingState.Idle(),
            locations: [],
            locationFormDialogOpen: false,
            locationFormDialogData: null,
            locationSelected: null,
        }

        this.reload = this.reload.bind(this);
        this.onClickReloadButton = this.onClickReloadButton.bind(this);
        this.closeLocationFormDialog = this.closeLocationFormDialog.bind(this);
        this.addNewLocation = this.addNewLocation.bind(this);

        this.reloadButtonPressedAt = 0;
                
        setInterval(function() {
            if(!document.hasFocus())
                return;
            show("Rozpoczęto aktualizację", "Rozpoczęto automatyczną aktualizację danych", "info", 2500);
            this.reload();
        }, 5*60000);    
    }

    reload(force = false) {
        if(!force && this.state.loadingState.isLoading())
            return;
        this.setState({
            loadingState: LoadingState.Loading(),
            locations: this.state.locations
        });
        fetch("/api/locations?withData")
            .then(res => {
                if(res.status == 200)
                    return res.json();
                if(res.status == 403) {
                    window.location.href = "/login";
                    throw Error("Unauthorized");
                }
                throw Error({message: "Server error: " + res.status, result: res});
            })
            .then(
                (result) => {
                    if(!force)
                        show("Zaaktualizowano", "Dane zostały pobrane pomyślnie", "success", 3000);
                    this.setState({
                        loadingState: LoadingState.Idle(),
                        locations: result
                    })
                },
                (error) => {
                    show("Wystąpił błąd podczas pobierania danych", "", "error", 4000);
                    this.setState({
                        loadingState: LoadingState.Error(),
                        locations: this.state.locations
                    });
                }
            )
    }

    onClickReloadButton() {
        var now = new Date().getTime();
        if(!this.state.loadingState.errorOccured() && now - this.reloadButtonPressedAt < 10*1000) {
            show("Uwaga!", "Klikasz zbyt szybko! <br> Odczekaj kilka sekund i spróbuj ponownie", "warning", 1500);
            return;
        }
        this.reloadButtonPressedAt = now;
        show("Rozpoczęto aktualizację", "Rozpoczęto aktualizację danych na prośbę użytkownika", "info", 2500);
        this.reload();
    }

    editLocation(location) {
        this.setState({
            locationFormDialogOpen: true,
            locationFormDialogData: location,
        });
    }

    addNewLocation() {
        if(this.state.locations.length >= this.props.userDetails.locationsLimit)
            return;
        this.setState({
            locationFormDialogOpen: true,
            locationFormDialogData: null,
        });
    }

    closeLocationFormDialog() {
        this.setState({
            locationFormDialogOpen: false,
            locationFormDialogData: null,
        });
    }

    componentDidMount() {
        this.reload();
    }

    onClickGetApiToken() {
        fetch("/user/api/token")
            .then(res => {
                console.log(res);
                if(res.status == 200)
                    return res.text();
                throw Error({message: "Server error: " + res.status, result: res});
            })
            .then(
                (result) => {
                    alert("Your api token: " + result)
                },
                (error) => {
                    show("Wystąpił błąd podczas pobierania tokenu", "", "error", 4000);
                }
            )
    }

    render() {
        const {error, isLoaded, locations} = this.state;
        const {userDetails} = this.props;
        
        return (
            <>
            <div className="flex-row" style={{columnGap: '12px', marginTop: '24px',}}>
                <img className="img-icon" id="refreshAllBtn" src="styles/icons/refresh.svg" alt="Refresh" onClick={this.onClickReloadButton}/>
                <img className="img-icon" id="getApiTokenBtn" src="pictures/code.svg" alt="Get api token" onClick={this.onClickGetApiToken}/>
            </div>
            <ul id="locations-container">
                { locations.map((location) => 
                    <li className="location-box" key={location.id} onClick={() => this.setState({locationSelected: location})}><LocationItem location={location}/></li>
                ) }
                <li className={`location-box add-new-location-container ${locations.length >= userDetails.locationsLimit? "disabled" : ""}`} key="-1" onClick={this.addNewLocation}>
                    <img className="img-icon" id="addNewLocationBtn" src="styles/icons/add_location.svg" alt="Add new location"/>
                    {locations.length < userDetails.locationsLimit?
                        <span>Add new location</span>
                        :
                        <span>Locations limit reached</span>
                    }
                </li>
            </ul>
            <LocationFormDialog open={this.state.locationFormDialogOpen} handleClose={(r) => {this.closeLocationFormDialog(); if(r != undefined) this.reload(true);}} locationToEdit={this.state.locationFormDialogData}/>                    
            { this.state.locationSelected != null?
                <Dialog open={this.state.locationSelected != null} onClose={() => this.setState({locationSelected: null})}>
                    <DialogTitle>Location: {this.state.locationSelected.name}</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({locationSelected: null})}
                            color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            this.setState({locationSelected: null});
                            this.editLocation(this.state.locationSelected)
                        }}
                            color="primary">
                            Edit
                        </Button>
                        <Button onClick={() => {
                                const requestOptions = {
                                    method: 'DELETE',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                                    },
                                    body: this.state.locationSelected.id
                                };
                                fetch('/api/locations', requestOptions)
                                .then((result) => {
                                    if(result.status == 200)
                                        this.reload(true);
                                    else
                                        show("Wystąpił nieznany błąd podczas usuwania lokalizacji", "", "error", 3000);
                                });
                                this.setState({locationSelected: null});
                            }}
                            color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                null
            }
            </>
        )
    }
}

export function LocationsContainer(props) {
    return (
        <>
        <LocationList userDetails={props.userDetails}/>
        </>
    )
}