import './Index.css';
import React from "react";
import UserDetails from '../../components/UserDetails';

function Index() {
  return (
    <>
{/*     <header> */}
{/*       <span>Weather.damianhoppe.pl</span> */}
{/*       <UserDetails isIndexPage="true"/> */}
{/*     </header> */}
    <main>
      <section>
        <div class="header">
          <span>Weather.damianhoppe.pl</span>
          <UserDetails isIndexPage="true"/>
        </div>
        <div class="flex-row flex-columns-2">
          <div>
            <h1>Free and comprehensive weather service</h1>
            <p>Receive notifications about approaching storms, next lightnings strikes and weather alerts for your location.</p>
          </div>
          <div class="promo-image">
            <img src="pictures/promo.png"/>
          </div>
        </div>
      </section>
      <section>
        <h2>Features</h2>
        <div class="articles">
          <article>
            <span class="icon-bg material-symbols-outlined">thunderstorm</span>
            <div className="bg-color">
              <h3>Lightnings strikes</h3>
              <p>
                Lightning data is downloaded from an external service via API - burze.dzis.net.
                For this reason, these data are only available for Poland.
                In the future, it is planned to join the blitrzortung network, but due to the large number of people willing to join,
                it may take a long time.
              </p>
            </div>
          </article>
          <article>
            <span class="icon-bg material-symbols-outlined">warning</span>
            <div className="bg-color">
              <h3>Weather alerts</h3>
              <p>
              Weather alerts apply only to Poland and are also downloaded from the API - burze.dzis.net.
              With the implementation of support for current weather conditions, this service will be expanded to serve more countries.
              </p>
            </div>
          </article>
          <article>
            <span class="icon-bg material-symbols-outlined">air</span>
            <div className="bg-color">
              <h3>Air pollution</h3>
              <p class="text-align-center">
                In progress...
              </p>
              <span class="material-symbols-outlined">hourglass_top</span>
            </div>
          </article>
          <article>
            <span class="icon-bg material-symbols-outlined">weather_mix</span>
            <div className="bg-color">
              <h3>Current weather</h3>
              <p class="text-align-center">
                In progress...
              </p>
              <span class="material-symbols-outlined">hourglass_top</span>
            </div>
            </article>
        </div>
      </section>
      <section>
        <h2>Subscription plans</h2>
        <table>
          <tr>
            <th></th>
            <th>Free</th>
            <th>Starter</th>
            <th>Pro</th>
            <th>Business</th>
          </tr>
          <tr>
            <td>Locations limit</td>
            <td>1</td>
            <td>5</td>
            <td>10</td>
            <td>Custom</td>
          </tr>
          <tr>
            <td>API calls per day</td>
            <td>25</td>
            <td>200</td>
            <td>1000</td>
            <td>Custom</td>
          </tr>
          <tr>
            <td>Mobile notifications</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes/WebHooks</td>
          </tr>
          <tr>
            <td>Lightnings update period</td>
            <td>&lt;&infin;</td>
            <td>&lt;10m</td>
            <td>&lt;5m</td>
            <td>&lt;1m</td>
          </tr>
          <tr>
            <td>Alerts update period</td>
            <td>&lt;&infin;</td>
            <td>&lt;3h</td>
            <td>&lt;1h</td>
            <td>Custom</td>
          </tr>
        </table>
        <p>
          *To change your current plan, contact us via email arguing your request accordingly,
          and include information on how and for what purpose you want to use the data.
        </p>
      </section>
      <section>
        <h2>API</h2>
        <div>
          <p>
            As a registered user, you have the ability to create a token to access our API,
            through which you will gain the ability to retrieve and edit data associated with your account:
            </p>
          <ul class="custom">
            <li>Viewing your locations</li>
            <li>Managing your locations</li>
            <li>Downloading weather data for your location</li>
            <li>Chaning the settings for your locations, if any, in your plan</li>
          </ul>
        </div>
      </section>
      <section>
        <h2>System architecture</h2>
        <ul class="custom">
          <li>
            Main server - manage your account and locations, download weather data and access data via Rest API
          </li>
          <li>
            Lightnings service - a service that calls an external api, in the future,
            changing the source of data retrieval (API) will not require updating the entire application and only this service
          </li>
          <li>
            Lightning update service - updates lightning information for locations trying not to exceed external api limits
            and prioritizing user locations with higher plans
          </li>
          <li>
          Weather alert update service - updates weather alert information for locations trying not to exceed external api limits
          and prioritizing user locations with higher plans
          </li>
          <li>
            Notifications service - sends notifications to registered mobile devices
          </li>
          <li>
            Redis - storing user sessions
          </li>
          <li>
            MySQL database - data storage for all services
          </li>
        </ul>
        <br></br>
        <h2>In progress:</h2>
        <ul class="custom">
          <li>
            Kafka - sending events, which are then received by the notification service
          </li>
          <li>
            Weather data update and air pollution services
          </li>
          <li>
            Notifications service - WebHooks
          </li>
          <li>
            Redis - cache for data retrieved from the database
          </li>
        </ul>
      </section>
      <section>
        <div class="text-align-center">&copy; Copyright 2023</div>
      </section>
    </main>
    <footer>
      <div class="text-align-center">&copy; Copyright 2023</div>
    </footer>
    </>
  );
}

export default Index;
